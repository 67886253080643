import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MovieWallTeaser.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { PromotionBadge } from '../PromotionBadge';
import { MoveWallTeaserContentModel } from './types';
import DOMPurify from 'dompurify';
import { generateImageUrl } from '../../utils/cdnService';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { CustomLink } from '../CustomLink';

type Props = {
  title: string;
  teaserPosition: string;
  content: MoveWallTeaserContentModel[];
  slug: string;
  image: string;
  text: string;
  id: number;
};

const MovieWallTeaser: FC<Props> = ({ title, teaserPosition, content, slug, image, text, id }) => {
  const navigate = useNavigate();
  const params = {
    loop: true,
    navigation: {
      nextEl: `${styles.nextButton}`,
      prevEl: `${styles.prevButton}`,
    },
    slidesPerView: 3,
    spaceBetween: 50,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      411: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      650: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      991: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  };
  const {
    i18n: { language },
  } = useTranslation();

  const ref = useRef<SwiperRef | null>(null);

  const handleNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slideNext();
    }
  };

  const handlePrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slidePrev();
    }
  };

  let position = 3;
  if (teaserPosition === 'left') {
    position = 1;
  }

  const renderGallery = () => {
    if (content && content?.length > 0) {
      return (
        <Swiper ref={ref} modules={[Navigation, Autoplay]} {...params}>
          {content.map((movie) => (
            <SwiperSlide key={movie.id}>
              <div className={styles.movieDetails}>
                {movie.promotional_image && movie.promotion_value && (
                  <PromotionBadge margin image={movie.promotional_image} value={movie.promotion_value} />
                )}
                <div>
                  {movie.banner ? (
                    <div
                      onClick={() => navigate(`/${language}/movie/${movie.id}`)}
                      className={`${styles.marker} ${styles[movie.banner]}`}
                    ></div>
                  ) : null}
                  <CustomLink to={`/${language}/movie/${movie.id}`}>
                    <img src={`${generateImageUrl('gcore', 242, 323, movie.image)}`} alt="movie" />
                  </CustomLink>
                </div>
                <div className={styles.movieLink}>
                  <CustomLink className={styles.movieTitle} to={`/${language}/movie/${movie.id}`}>
                    {movie.title}
                  </CustomLink>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
  };

  return (
    <div className={styles.secondarySlider}>
      <div className={styles.headline}>
        <div onClick={() => navigate(`/${language}/themes/${id}`)} className={styles.title}>
          <h2>{title}</h2>
        </div>
        <div className={styles.link}>
          <CustomLink to={`/${language}/themes/${id}`}>
            ALLE <strong>{slug}</strong>
          </CustomLink>
        </div>
      </div>
      <div>
        <Row>
          <Col lg={{ span: 8, order: 2 }} md={7} sm={12}>
            <div className={styles.swiper}>
              {renderGallery()}
              <div onClick={handleNext} className={styles.nextButton}>
                <i className="fa fa-angle-right" aria-hidden="true" style={{ padding: '0px 4px' }} />{' '}
              </div>
              <div onClick={handlePrev} className={styles.prevButton}>
                <i className="fa fa-angle-left" aria-hidden="true" />
              </div>
            </div>
          </Col>
          <Col className={styles.teaser} lg={{ span: 4, order: position }} md={5}>
            <CustomLink className={styles.teaserLink} to={`/${language}/themes/${id}`}>
              {image && <img alt="teaser" src={image} />}
              {text && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />}
            </CustomLink>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MovieWallTeaser;
