import { UseMutateFunction } from '@tanstack/react-query';
import { BASE_URL } from 'src/core/constants/constants';
import { PaymentInitModel } from '../mutation/usePaymentInitMutation';

const handleRocketGatePayment = (
  setIsLoading: (value: boolean) => void,
  paymentInitMutate: UseMutateFunction<unknown, unknown, PaymentInitModel, unknown>,
  language: string,
  navigate: any,
  id?: string
) => {
  setIsLoading(true);

  try {
    paymentInitMutate(
      {
        product_id: 1,
        payment_type_id: 29,
        gateway: 'RocketGate',
        product_price_id: 257,
        redirect_url: `${BASE_URL}/${language}/payment/message/RocketGate`,
        success_url: `http://localhost:3000/${language}/edit-payment-method`,
      },
      {
        onSuccess(data: any) {
          const { url } = (data as { data: { url: string } }).data;
          window.location.href = url;
        },
        onError() {
          navigate(`/${language}/payment/message/error`);
          setIsLoading(false);
        },
      }
    );
  } catch (e) {
    navigate(`/${language}/payment/message/error`);
    setIsLoading(false);
  }
};

export default handleRocketGatePayment;
