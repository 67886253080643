import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './PaymentMethodCard.module.scss';
import { PaymentUsed } from '../../hooks/models/PaymentUsedModel';
import { generateImageUrl } from '../../utils/cdnService';
import { useTranslation } from 'react-i18next';

interface PaymentMethodCardI {
  payment: PaymentUsed;
  selectedPayment: PaymentUsed;
  setSelectedPayment: Dispatch<SetStateAction<PaymentUsed>>;
  handleDelete: (id: number) => void;
  handleShowConfirmModal: (payment: PaymentUsed) => void;
  handleCloseConfirmModal: () => void;
  index: number;
}

const PaymentMethodCard: FC<PaymentMethodCardI> = ({ payment, handleDelete, handleShowConfirmModal, index }) => {
  const { t } = useTranslation();
  const handleSelectPayment = () => {
    if (payment.state !== 'A') return;
    if (payment.gateway === 'QBOX') return;
    handleShowConfirmModal(payment);
  };
  const selected = index === 0;

  const isDisabled = payment.gateway === 'QBOX' || payment.state === 'B';
  const showTrashIcon = !selected && payment.gateway !== 'QBOX';

  return (
    <>
      <div className={`${styles.container} ${selected ? styles.selected : ''} ${isDisabled ? styles.blocked : ''}`}>
        <div className={styles.mainContent}>
          <header>
            <div className={styles.imgAndStatus}>
              {payment.image && (
                <img
                  className={`${styles.img} ${payment.disabled ? styles.paypalImg : ''} ${
                    payment.gateway === 'QBOX' && styles.qboxImg
                  }`}
                  src={`${generateImageUrl('gcore', 0, 0, payment.image)}`}
                  alt=""
                />
              )}
              <div style={{ background: payment.state === 'A' ? '#1a8754' : '#B51511' }} className={styles.status}>
                {payment.state === 'A' ? 'Aktiv' : 'Inaktiv'}
              </div>
            </div>
            {showTrashIcon && (
              <button onClick={() => handleDelete(payment.id)} className={styles.trashButton}>
                <i className="fa fa-trash red-icon" style={{ color: 'red' }} aria-hidden="true"></i>
              </button>
            )}
          </header>
          <span>
            {payment.gateway === 'QBOX' && t('changePaymentMethod.contractNumber')}
            {payment.info}
          </span>
        </div>
        {!isDisabled && (
          <button className={`${styles.btn} ${!selected ? styles.notSelected : ''}`} onClick={handleSelectPayment}>
            {selected ? t('changePaymentMethod.active') : t('changePaymentMethod.change')}
          </button>
        )}
      </div>
    </>
  );
};

export default PaymentMethodCard;
