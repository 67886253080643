import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './AddNewPaymentMethod.module.scss';
import plusIcon from '../../../assets/img/plusIcon.svg';
import plusIconInactive from '../../../assets/img/plusIconInactive.svg';
import masterCard from '../../../assets/img/masterCard.svg';
import visa from '../../../assets/img/visa.svg';
import sepa from '../../../assets/img/sepaLogo.svg';

interface AddNewPaymentMethodI {
  isSepa: boolean;
  handleSepaShow?: Dispatch<SetStateAction<boolean>>;
  handleRocektGatePayment?: () => void;
}

const AddNewPaymentMethod: FC<AddNewPaymentMethodI> = ({ isSepa, handleSepaShow, handleRocektGatePayment }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = () => {
    if (isSepa) {
      handleSepaShow && handleSepaShow(true);
    }

    if(handleRocektGatePayment){
      handleRocektGatePayment();
    }
  };

  return (
    <div
      onClick={handleClick}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      className={styles.container}
    >
      <div>
        <img src={isHovered ? plusIcon : plusIconInactive} alt="plusIcon" />
      </div>
      {isSepa ? (
        <div>
          <img src={sepa} alt="" />
        </div>
      ) : (
        <div className={styles.visaMaser}>
          <img src={masterCard} alt="" />
          <img src={visa} alt="" />
        </div>
      )}
    </div>
  );
};

export default AddNewPaymentMethod;
