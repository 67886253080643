import React, { FC, Suspense, useContext, useState } from 'react';
import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import styles from './BoughtMovies.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Balance } from '../../components/Balance';
import { useTranslation } from 'react-i18next';
import { CustomSpinner } from '../../shared/Spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { BigButton } from '../../components/UI/BigButton';
import { PinConfirmationModal } from '../../components/UI/Modals/PinConfirmationModal';
import { OrderModal } from '../../components/UI/Modals/OrderModal';
import { AuthContext } from '../../context/auth';
import { Player } from '../../components/UI/Player';
import ReactPaginate from 'react-paginate';
import { usePurchasedContentQuery, usePurchasedContentStudiosQuery } from './query';
import { URLBoughtMoviesQueryStateModel } from './types';
import { BoughtMoviesModel } from './query/types';
import usePurchaseMovieMutation from './mutation/usePurchaseMovieMutation';
import { BoughtMoviesList } from './components/BoughtMoviesList';

const BoughtMovies: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, updateUser } = authContext;
  const [playerStatus, setPlayerStatus] = useState<boolean>(false);
  const [orderVisibility, setOrderVisibility] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [selectedMovie, setSelectedMovie] = useState<BoughtMoviesModel>();
  const [cfMediaID, setCfMediaID] = useState<string>('');
  const [query, setQuery] = useState<URLBoughtMoviesQueryStateModel>({
    page: 1,
  });
  const [selected, setSelected] = useState<{ label: string; value: number }[]>([]);

  const { boughtMovies, areBoughtMoviesLoaded, boughtMoviesMeta, refetchBoughtMovies } =
    usePurchasedContentQuery(query);
  const { studios, areStudiosLoading } = usePurchasedContentStudiosQuery();
  const { mutate } = usePurchaseMovieMutation();



  const renderDownloadHD = (movie: BoughtMoviesModel) => {
    if (movie['download-hd']?.price !== 0) {
      return (
        <BigButton
          sm={6}
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 3 : 4}
          onClick={() => {
            setOrderVisibility(true);
            setSelectedMovie(movie);
            setSelectedButton('hd');
            setSelectedPrice(movie['download-hd']?.price);
          }}
          icon="fa fa-download"
          text={`Kaufen (HD) FüR ${movie['download-hd']?.price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
          })}`}
        />
      );
    }
    return (
      <BigButton
        active
        sm={6}
        md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 3 : 4}
        onClick={() => {
          setShow(true);
          setSelectedButton('hd');
          setSelectedMovie(movie);
        }}
        icon="fa fa-download"
        text={`Download (HD) FüR ${movie['download-hd'].hours_to_expire}`}
      />
    );
  };

  const renderBmButton = (movie: BoughtMoviesModel) => {
    if (movie && movie.banner === 'blue-movie-plus' && !user.subscribed) {
      return (
        <BigButton
          sm={6}
          md={3}
          share
          onClick={() =>
            navigate(`/${language}/blue-movie-plus`, {
              state: { location: 'singleMovie' },
            })
          }
          icon="fa fa-share"
          text="Blue Movie Plus kaufen"
        />
      );
    }
  };

  const renderStreamButton = (movie: BoughtMoviesModel) => {
    if (movie && movie.stream?.license_type === 'subscription-stream') {
      return (
        <BigButton
          active
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 3 : 4}
          sm={6}
          onClick={() => {
            setShow(true);
            setSelectedButton('play');
            setSelectedMovie(movie);
          }}
          icon="fa fa-play-circle"
          text="Film ansehien"
        />
      );
    } else {
      if (movie.stream?.price === 0 && movie.stream.hours_to_expire) {
        return (
          <BigButton
            active
            md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 3 : 4}
            sm={6}
            onClick={() => {
              setShow(true);
              setSelectedButton('play');
              setSelectedMovie(movie);
            }}
            icon="fa fa-play-circle"
            text={`Ausgeliehen FüR ${movie.stream.hours_to_expire}`}
          />
        );
      }
      return (
        <BigButton
          active={movie.stream?.price === 0 ? true : false}
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 3 : 4}
          sm={6}
          onClick={() => {
            movie.stream?.price === 0 ? setShow(true) : setOrderVisibility(true);
            setSelectedButton('play');
            setSelectedMovie(movie);
            setSelectedPrice(movie?.stream?.price);
          }}
          icon="fa fa-play-circle"
          text={`leihen FüR ${movie?.stream?.price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
          })} / 48 STD`}
        />
      );
    }
  };

  const formatLabel = (name: string, no: number) => {
    const n = 15;
    const label = name && name?.length > n ? name.substr(0, n - 1) + '...' : name;
    return `${label} (${no})`;
  };

  const renderMenu = (movie: BoughtMoviesModel) => {
    if (!areBoughtMoviesLoaded && movie && isAuthenticated && !loading) {
      return (
        <Col xs={12}>
          <Row>
            {movie && movie.stream && renderStreamButton(movie)}
            {movie && movie.stream && renderDownloadHD(movie)}
            {movie && movie.stream && renderBmButton(movie)}
          </Row>
        </Col>
      );
    }
  };
  const handleClose = () => setShow(false);

  const handlePlayMovie = async (movie: BoughtMoviesModel) => {
    let data = {};
    if (selectedButton === 'hd') {
      data = {
        price: movie['download-hd']?.price,
        license_type: 'download-hd',
        content_name: movie.title,
      };
    } else if (selectedButton === 'sd') {
      data = {
        price: movie?.download?.price,
        license_type: 'download',
        content_name: movie.title,
      };
    } else if (selectedButton === 'play') {
      data = {
        price: movie?.stream?.price,
        license_type: movie.stream.license_type,
        content_name: movie.title,
      };
    }

    try {
      mutate(
        { movie, data },
        {
          onSuccess(data: any) {
            if (selectedButton === 'sd' || selectedButton === 'hd') {
              updateUser({
                balance: data.data.balance,
              });
              setTimeout(function () {
                document.location.href = `${data.data.url}`;
              }, 500);
            } else {
              updateUser({
                balance: data.data.balance,
              });
              setCfMediaID(data.data.url);
              setPlayerStatus(true);
            }
            queryClient.invalidateQueries({ queryKey: [`movies_${movie.id}`] });
          },
        }
      );

      setOrderVisibility(false);
      setShow(false);
      setTimeout(function () {
        refetchBoughtMovies();
      }, 800);
    } catch (e) {}
  };

  const renderStream = () => {
    if (!areBoughtMoviesLoaded && selectedMovie && boughtMovies && isAuthenticated && playerStatus) {
      return (
        <Player banner={selectedMovie.banner} onHide={onHide} id={selectedMovie.id} controls muted src={cfMediaID} />
      );
    }
  };
  const onHide = () => {
    setPlayerStatus(false);
  };

  if (areBoughtMoviesLoaded || areStudiosLoading) {
    return <CustomSpinner />;
  }

  const handleStudioChange = (values: { label: string; value: number }[]) => {
    setSelected(values);
    if (values && values?.length < 0) {
    }
    const collection = values.map((v) => v.value);
    setQuery({
      ...query,
      studio: collection,
    });
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuery({
      ...query,
      sort: e.target.value,
    });
    if (e.target.value === 'new') {
      setQuery({
        ...query,
        sort: 'license.online_start',
      });
    }
    if (e.target.value === 'old') {
      setQuery({
        ...query,
        sort: '-license.online_start',
      });
    }
    if (e.target.value === 'title-a') {
      setQuery({
        ...query,
        sort: 'content_data.title',
      });
    }
    if (e.target.value === 'title-d') {
      setQuery({
        ...query,
        sort: '-content_data.title',
      });
    }
  };
  const renderPagination = () => {
    if (boughtMovies && boughtMoviesMeta && !areBoughtMoviesLoaded) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left"></i>}
          nextLabel={<i className="fa fa-chevron-right"></i>}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={boughtMovies && boughtMoviesMeta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={
            boughtMoviesMeta && query.page === boughtMoviesMeta.last_page
              ? `${styles.hideButton}`
              : `${styles.showButton}`
          }
        />
      );
    }
    return null;
  };

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };

  return (
    <Container>
      <Suspense fallback={<CustomSpinner />}>
        <div className={styles.pageHeader}>
          <Balance />
        </div>
        <h2>{t('boughtMoviesPage.title')}</h2>
        <Row>
          <Col sm={{ span: 9, offset: 3 }}>
            <div className={styles.paginationWrapper}>
              <div className={styles.pagination}>{renderPagination()}</div>
            </div>
          </Col>
        </Row>

        <BoughtMoviesList
          renderMenu={renderMenu}
          boughtMovies={boughtMovies}
          handleStudioChange={handleStudioChange}
          selected={selected}
          formatLabel={formatLabel}
          query={query}
          studios={studios}
          areStudiosLoading={areStudiosLoading}
          handleOptionChange={handleOptionChange}
        />
        <Row>
          <Col sm={{ span: 9, offset: 3 }}>
            <div className={styles.paginationWrapper}>
              <div className={styles.pagination}>{renderPagination()}</div>
            </div>
          </Col>
        </Row>

        <Row>
          <PinConfirmationModal
            selectedButton={selectedButton}
            handleSuccess={() => handlePlayMovie(selectedMovie as BoughtMoviesModel)}
            centered={true}
            show={show}
            onHide={handleClose}
          />
          <OrderModal
            selectedButton={selectedButton}
            balance={user && (user.balance?.toFixed(2) as string)}
            location={location.pathname}
            price={selectedPrice}
            handleSuccess={() => handlePlayMovie(selectedMovie as BoughtMoviesModel)}
            centered={true}
            show={orderVisibility}
            onHide={() => setOrderVisibility(false)}
          />
        </Row>
      </Suspense>
      {boughtMovies && cfMediaID && renderStream()}
    </Container>
  );
};

export default BoughtMovies;
