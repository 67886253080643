import React, { FC, useState } from 'react';
import styles from './PaymentModal.module.scss';
import Modal from 'react-bootstrap/Modal';
import iban from 'iban';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/img/logobm.png';
import { Formik, Field, Form, FormikErrors } from 'formik';
import * as Yup from 'yup';
import closeIcon from '../../../../assets/img/closeIcon.svg';

type Props = {
  amount?: number;
  show: boolean;
  handleClose: () => void;
  handlePayment: ({
    iban,
    name,
    lastName,
    bank,
  }: {
    iban: string;
    name: string;
    lastName: string;
    bank: string;
  }) => void;
};

interface FormValues {
  iban: string;
  name: string;
  lastName: string;
  bank: string;
}

const PaymentModal: FC<Props> = ({ amount, show, handleClose, handlePayment }) => {
  const { t } = useTranslation();
  const [paymentData, setPaymentData] = useState<FormValues>({
    iban: '',
    name: '',
    lastName: '',
    bank: '',
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('iban', event.target.value);
    let v = event.target.value;
    if (paymentData.iban && (paymentData.iban.replace(/\s/g, '')?.length + 1) % 4 === 0) {
      v = `${event.target.value}  `;
    }
    setPaymentData((prevState) => ({ ...prevState, iban: v }));
  };

  const handlePaymentClick = (values: FormValues, errors: FormikErrors<FormValues>) => {
    if (errors.iban) return;
    handlePayment({
      ...values,
      iban: paymentData.iban,
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('paymentModal.accountHolderNameValidation')),
    lastName: Yup.string().required(t('paymentModal.accountHolderFirstNameValidation')),
    bank: Yup.string().required(t('paymentModal.creditInstituteValidation')),
    iban: Yup.string().test('is-valid-iban', 'Bitte gültige IBAN eingeben', (value) => iban.isValid(value || '')),
  });

  const initialValues: FormValues = {
    name: '',
    lastName: '',
    iban: '',
    bank: '',
  };

  return (
    <Modal contentClassName={styles.paymentModalWrapper} show={show} onHide={handleClose} centered>
      <Modal.Header className={styles.header}>
        <div className={styles.logoAndText}>
          <img width={118} height={58} src={Logo} alt="logo" />
          <h2>Sepa Direct Debit</h2>
        </div>
        <img onClick={handleClose} className={styles.closeIcon} src={closeIcon} alt="close icon" />
      </Modal.Header>
      <Modal.Body className={`${styles.modalBody}`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => handlePaymentClick(values, {})}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Field name="name" type="text" placeholder={t('paymentModal.accountHolderName')} />
              {errors.name && touched.name ? <span>{errors.name}</span> : null}
              <Field name="lastName" type="text" placeholder={t('paymentModal.accountHolderFirstName')} />
              {errors.lastName && touched.lastName ? <span>{errors.lastName}</span> : null}
              <Field
                name="iban"
                type="text"
                placeholder={t('paymentModal.iban')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setFieldValue)}
                value={paymentData.iban}
              />
              {errors.iban && touched.iban ? <span>{errors.iban}</span> : null}
              <Field name="bank" type="text" placeholder={t('paymentModal.creditInstitute')} />
              {errors.bank && touched.bank ? <span>{errors.bank}</span> : null}
              {amount ? (
                <button type="submit">{amount.toFixed(2)} € bezahlen</button>
              ) : (
                <button type="submit">{t('paymentModal.addNewPayment')}</button>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
