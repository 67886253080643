import React, { FC, Suspense, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { MovieWall } from '../../components/MovieWall';
import { MovieWallTeaser } from '../../components/MovieWallTeaser';
import styles from './HomePage.module.scss';
import { CustomSpinner } from '../../shared/Spinner';
import { BannersList } from '../../components/Banners';
import { AuthContext } from 'src/context/auth';
import { useHomepageAllQuery } from './query';
import USP from '../../components/Home/USP';
import { generateImageUrl } from '../../utils/cdnService';

const HomePage: FC = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  const { homePage, isLoading } = useHomepageAllQuery(isAuthenticated);

  const getTeaserPosition = (index: number) => {
    index++;
    index += 1;
    if (index % 2 === 0) {
      return 'left';
    } else {
      return 'right';
    }
  };

  const renderSections = () => {
    if (homePage?.data && homePage?.data.themes?.length) {
      let themes = homePage.data.themes;

      if (isAuthenticated) {
        themes = themes.filter((item) => !item?.slug?.includes('top-movies'));
      }

      return themes.map((section, index) => (
        <MovieWallTeaser
          key={index}
          id={section.id}
          teaserPosition={getTeaserPosition(index)}
          title={section.title}
          slug={section.slug}
          content={section.content}
          text={section.text}
          image={section.image && `${generateImageUrl('gcore', 350, 270, section.image)}`}
        />
      ));
    }
  };

  if (isLoading) {
    return (
      <div className={styles.homeWrapper}>
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Suspense fallback={<CustomSpinner />}>
          {homePage?.data && homePage?.data.banners && (
            <BannersList banners={homePage?.data.banners} alt="home-page-img" />
          )}
        </Suspense>
        {homePage?.data && homePage?.data.brandNew && isAuthenticated && (
          <MovieWall title={'NEUSTARTS'} rightText={'neustarts'} data={homePage?.data.brandNew} />
        )}
        <USP />

        {renderSections()}
      </Container>
    </div>
  );
};

export default HomePage;
