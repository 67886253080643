import React, { FC, FormEvent, useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './ContactPage.module.scss';
import { FileUploader } from '../../../components/FileUploader';
import { AuthContext } from '../../../context/auth';
import Alert from 'react-bootstrap/Alert';
import { LoginButton } from '../../../components/UI/LoginButton';
import { useContactSupportMutation } from './mutation';

const ContactPage: FC = () => {
  const authContext = useContext(AuthContext);
  const customerNr = localStorage.getItem('blue_customerNumber') || '';

  const { isAuthenticated, user } = authContext;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [questionType, setQuestionType] = useState<string>('inhalt-melden');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSubmited, setFormSubmited] = useState<{ status: boolean; message: string; error: boolean }>({
    status: false,
    message: '',
    error: false,
  });
  const { mutateContactSupport } = useContactSupportMutation();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(formData);
    formData.append('user_email', email);
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('question_type', questionType);
    if (customerNr && customerNr !== '') {
      formData.append('customerNr', customerNr);
    } else {
      formData.append('customerNr', customerNumber);
    }
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    setIsLoading(true);

    mutateContactSupport(formData, {
      onSuccess() {
        setFormSubmited({
          status: true,
          message: 'Email sent',
          error: false,
        });
        setMessage('');
        setEmail('');
        setSubject('');
        setSelectedFile(null);
        setIsLoading(false);
        setCustomerNumber('');
        setQuestionType('');
      },
      onError() {
        setIsLoading(false);
        setFormSubmited({
          status: false,
          error: true,
          message: 'Error sending email!',
        });
      },
    });
  };
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h2>KONTAKT ZUM KUNDENSERVICE</h2>
        </Col>
      </Row>
      <Row>
        <Col md={5} sm={12}>
          <p>Bitte füllen Sie das nachfolgende Formular aus.</p>
          <p>Wir werden Ihnen so schnell wie möglich antworten.</p>
        </Col>
        <Col md={7} sm={12}>
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="">E-Mail*</label>
              <input required value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="">Betreff*</label>
              <input required value={subject} onChange={(e) => setSubject(e.target.value)} type="text" />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="requestType">Art der Anfrage*</label>
              <select id="requestType" value={questionType} required onChange={(e) => setQuestionType(e.target.value)}>
                <option value="inhalt-melden">Inhalt melden</option>
                <option value="entfernung-von-inhalten-beantragen">Entfernung von Inhalten beantragen</option>
                <option value="urheberrechtsverletzung-melden">Eine Urheberrechtsverletzung melden</option>
                <option value="datenschutzanfrage">Datenschutzanfrage</option>
                <option value="missbrauch-melden">Missbrauch melden</option>
                <option value="allgemeine-beschwerde">Allgemeine Beschwerde</option>
                <option value="technisches-problem-melden">Technisches Problem melden</option>
                <option value="fragen-zu-rechnungen-oder-zahlungen">Fragen zu Rechnungen oder Zahlungen</option>
                <option value="feedback-und-verbesserungsvorschlaege">Feedback und Verbesserungsvorschläge</option>
                <option value="andere">Andere</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="">Ihre Nachricht*</label>
              <textarea required value={message} onChange={(e) => setMessage(e.target.value)} cols={35} rows={4} />
            </div>

            {isAuthenticated ? (
              // TODO: Isn't working cause BE doesn't receive FormData
              <div>
                <FileUploader
                  submited={formSubmited.status}
                  onFileSelectSuccess={(file) => setSelectedFile(file)}
                  onFileSelectError={({ error }) => alert(error)}
                />
              </div>
            ) : (
              <div className={styles.formGroup}>
                <label htmlFor="">Sky Kundennummer</label>
                <input
                  required
                  value={customerNumber}
                  onChange={(e) => setCustomerNumber(e.target.value)}
                  type="customerNr"
                />
              </div>
            )}
            <div className={styles.formGroup}>
              {formSubmited.error && (
                <Alert style={{ width: '60%' }} show={formSubmited.error} variant={'danger'}>
                  {formSubmited.message}
                </Alert>
              )}
              {formSubmited.error === false && formSubmited.status === true && (
                <Alert style={{ width: '60%' }} show={formSubmited.status === true} variant={'primary'}>
                  {formSubmited.message}
                </Alert>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', width: '60%' }}>
              <LoginButton loading={isLoading} handleLogin={(e) => handleSubmit(e)} />
            </div>
          </form>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={5}>
          <p>Wir geben Ihre persönlichen Daten nicht an Dritte weiter.</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p>
            Wenn Sie Fragen zu Ihren Zahlungen über SEGPAY (PayPal und Kreditkarte) haben, können Sie sich auch
            jederzeit an den{' '}
            <a
              href="https://segpay.com/contact-us/#"
              target="_blank"
              rel="noopener noreferrer"
              title="https://epoch.com"
            >
              Segpay-Support
            </a>{' '}
            wenden.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p>
            Jede eingehende Beschwerde wird bearbeitet. Der Antragsteller erhält innerhalb von 7 Werktagen oder früher
            eine Rückmeldung.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
