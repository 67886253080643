import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './PageNotFound.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomLink } from 'src/components/CustomLink';

const PageNotFound: FC = () => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <Row>
          <Col className={styles.content} sm={8}>
            <h2>LEIDER STEHT DIE VON IHNEN ANGEFORDERTE SEITE NICHT ZUR VERFÜGUNG.</h2>
            <p>Vielleicht möchten Sie stattdessen eine der folgenden Aktionen durchführen</p>
            <ul>
              <li>
                In unserer <CustomLink to="/de/blue-movie-plus?page=1">Filmsammlung</CustomLink> stöbern
              </li>
              <li>
                {' '}
                <CustomLink to="/de/support">Kontakt</CustomLink> mit uns aufnehmen.
              </li>
            </ul>
            <p>Wir wünschen Ihnen weiterhin viel erotisches Vergnügen mit dem Angebot von Blue Movie.</p>
            <div className={styles.buttonWrapper}>
              <CustomLink className={styles.button} to="/">
                zurück zum Angebot
              </CustomLink>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PageNotFound;
