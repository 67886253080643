import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import { fetch as fetchRedirect } from 'src/utils/refetch';

interface AddNewSepaPaymentBodyModel {
  iban: string;
  name: string;
  lastName: string;
  bank: string;
}

const useAddNewSepaPaymentMutation = () => {
  const { mutate: mutateAddNewSepaPayment, isLoading: isAddNewSepaPaymentMutationLoading } = useMutation({
    mutationFn: async (data: AddNewSepaPaymentBodyModel) => {
      const { name, lastName, iban, bank } = data;
      const response = await fetchRedirect(createUrl(`/payment/add-sepa`), {
        method: 'POST',
        body: JSON.stringify({ bank_name: bank, iban: iban, first_name: name, last_name: lastName }),
      });

      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentTypesUsed']);
    },
  });

  return { mutateAddNewSepaPayment, isAddNewSepaPaymentMutationLoading };
};

export default useAddNewSepaPaymentMutation;
