import { UseMutateFunction } from '@tanstack/react-query';
import { BASE_URL } from 'src/core/constants/constants';
import { PaymentInitModel } from '../mutation/usePaymentInitMutation';
import { PaymentDataModel } from '../mutation/usePaymentMutation';
import { PaymentTypesModel } from '../query/types';

const oneClickPayment = (
  setIsLoading: (value: boolean) => void,
  setShowPaymentModal: (value: boolean) => void,
  selectedType: PaymentTypesModel,
  mutate: UseMutateFunction<unknown, unknown, PaymentDataModel, unknown>,
  loadUser: () => void,
  language: string,
  navigate: any,
  location: any,
  paymentInitMutate: UseMutateFunction<unknown, unknown, PaymentInitModel, unknown>,
  priceId: number | undefined,
  productId: number,
  id?: string
) => {
  setIsLoading(true);
  //ONECLICK PAYMENT
  if (selectedType.id === 0) {
    setIsLoading(true);
    try {
      mutate(
        {
          product_price_id: priceId,
          id: selectedType.last_id,
          gateway: selectedType.gateway,
          product_id: productId,
          redirect_url: `${BASE_URL}/${language}/payment/message/${selectedType.gateway}`,
        },
        {
          onSuccess(data: any) {
            if (data.status === 'success') {
              loadUser();
              setIsLoading(false);
              navigate(`/${language}/payment/message/success`, {
                state: {
                  prevPath: location.pathname,
                },
              });
            }
          },
          onError() {
            setIsLoading(false);
            navigate(`/${language}/payment/message/error`);
          },
        }
      );
    } catch (e) {
      navigate(`/${language}/payment/message/error`);
      setIsLoading(false);
    }
    return;
  }

  let token_id;
  try {
    paymentInitMutate(
      {
        product_price_id: priceId,
        payment_type_id: selectedType.id,
        gateway: selectedType.gateway,
        redirect_url: `${BASE_URL}/${language}/payment/message/${selectedType.gateway}`,
        product_id: productId,
        success_url: `${BASE_URL}/${language}/payment/message/success?successId=${id}`,
      },
      {
        onSuccess(data: any) {
          const { url, x_order_id, token } = (data as { data: { token: string; url: string; x_order_id: number } })
            .data;
          console.log(data, ' payment');
          token_id = token;
          localStorage.setItem('blue_x_order_id', JSON.stringify(x_order_id));
          if (
            selectedType.gateway === 'Segpay' ||
            selectedType.gateway === 'Epoch' ||
            selectedType.gateway === 'RocketGate' ||
            (selectedType.gateway === 'MicroPayment' && selectedType.name?.includes('Sofort'))
          ) {
            window.location.href = url;
            return;
          }

          if (selectedType.gateway === 'MicroPayment') {
            setIsLoading(false);
            setShowPaymentModal(true);
            return;
          }
        },
        onError(error: any) {
          window.location.href = `/${language}/payment/message/error`;
        },
      }
    );
  } catch (e) {
    setIsLoading(false);
  }

  if (
    (selectedType.gateway === 'MicroPayment' && selectedType.name?.includes('Sepa')) ||
    selectedType.name?.includes('CommPay')
  ) {
    setIsLoading(false);
    setShowPaymentModal(true);
    return;
  }

  if (
    selectedType.gateway === 'Epoch' ||
    selectedType.gateway === 'RocketGate' ||
    (selectedType.gateway === 'MicroPayment' && selectedType.name?.includes('Sofort'))
  ) {
    ///TESTING
    setIsLoading(false);
    return;
  }

  let checkout = window.checkout(token_id);

  // Initialize the payment page
  checkout
    .init()
    .then(function () {
      // On success open the dialogue
      checkout.open();

      // Add your event listeners for abort or success event
      checkout.abort(() => {
        // $errorHolder.html('Transaction canceled by user.');
        // $submitButton.attr("disabled", false);
        setIsLoading(false);
      });

      checkout.success(() => {
        // redirect to result handler
        if (selectedType.id === 4) {
          navigate(`/${language}/payment/message/${selectedType.gateway}`);
        }
        setIsLoading(false);
      });

      checkout.error(() => {
        // redirect to result handler
        if (selectedType.id === 4) {
          navigate(`/${language}/payment/message/${selectedType.gateway}`);
        }
        setIsLoading(false);
      });
    })
    .catch((error: unknown) => {
      // handle error on init
      console.error(error);
      setIsLoading(false);
    });
};

export default oneClickPayment;
