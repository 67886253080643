import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './AddRocketGateModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

type Props = {
  centered: boolean;
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
};

const AddRocketGateModal: FC<Props> = ({ centered, show, onHide, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white"></Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col>
            <h2>{t('addRocketGate.title')}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t('addRocketGate.text')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <button style={{ background: 'grey' }} onClick={onHide}>
              {t('addRocketGate.cancel')}
            </button>
          </Col>
          <Col sm="6">
            <button onClick={onConfirm}>{t('addRocketGate.yes')}</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddRocketGateModal;
