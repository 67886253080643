import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { ChangePrimaryPaymentBodyModel } from '../models/ChangePrimaryPaymentBodyModel';
import { queryClient } from '../../core/root/components/ReactQueryProvider/ReactQueryProvider';

const useDeletePaymentMutation = () => {
  const { mutate: mutateDeletePayment, isLoading: isDeletePaymentMutationLoading } = useMutation({
    mutationFn: async (id: number) => {
      const response = await fetchRedirect(createUrl(`/payment/remove/${id}`), {
        method: 'DELETE',
      });

      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentTypesUsed']);
    },
  });

  return { mutateDeletePayment, isDeletePaymentMutationLoading };
};

export default useDeletePaymentMutation;
