import React, { FC, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import styles from './EmailModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useEmailMutation } from './mutation';
import { EmailDataModel } from './types';
import Alert from 'react-bootstrap/Alert';

type Props = {
  centered: boolean;
  show: boolean;
  onHide: () => void;
  ticket: EmailDataModel['ticket'];
  handleSuccess: () => void;
};

const EmailModal: FC<Props> = ({ centered, show, onHide, ticket, handleSuccess }) => {
  const { t } = useTranslation();
  const [isAlertShow, setIsAlertShow] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | undefined>('');
  const { mutate, isLoading } = useEmailMutation();

  useEffect(() => {
    if (isAlertShow) {
      setTimeout(() => {
        setIsError(false);
        setIsAlertShow(false);
      }, 3000);
    }
  }, [isAlertShow]);

  const validationSchema = Yup.object({
    user_email: Yup.string().email('*Ungültige E-Mail-Adresse').required('*Ungültige E-Mail-Adresse'),
    subject: Yup.string().required('*Betreff eingeben'),
    message: Yup.string().required('*Kündigungsgrund angeben'),
  });

  const handleSubmit = (values: EmailDataModel) => {
    sendEmail(values);
  };

  const sendEmail = (data: EmailDataModel) => {
    mutate(data, {
      onSuccess() {
        handleSuccess();
        setIsAlertShow(true);
      },
      onError() {
        setIsError(true);
      },
      onSettled(data, error) {
        setStatusMessage((data as Partial<EmailDataModel>).message);
        console.error(error);
      },
    });
  };

  const handleHide = () => {
    setStatusMessage('');
    onHide();
  };

  console.log(statusMessage, 'status message');
  return (
    <>
      <Alert style={{ maxWidth: '300px' }} transition show={isAlertShow} variant={isError ? 'danger' : 'success'}>
        {isError ? 'Wird gesendet ...' : 'E-Mail senden'}
      </Alert>
      <Modal centered={centered} show={show} onHide={() => handleHide()}>
        <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
          <Modal.Title className={styles.title}>{t('emailModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Formik
            initialValues={{ user_email: '', subject: 'Kündigung meines BM+ Abonnements', message: '', ticket }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row>
                  <Col>
                    <p>{t('emailModal.subTitle')}</p>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginBottom: 10 }} sm="7">
                    <Field
                      name="user_email"
                      type="email"
                      placeholder={t('emailModal.yourEmail')}
                      className="form-control"
                      style={{ marginBottom: 5 }}
                    />
                    <ErrorMessage name="user_email" component="div" className="text-danger" />
                  </Col>
                  <Col style={{ marginBottom: 10 }} sm="7">
                    <Field
                      name="subject"
                      type="text"
                      placeholder="Betreff"
                      className="form-control"
                      style={{ marginBottom: 5 }}
                    />
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </Col>
                  <Col style={{ marginBottom: 10 }} sm="7">
                    <Field
                      name="message"
                      as="textarea"
                      rows={7}
                      placeholder={t('emailModal.message')}
                      className="form-control"
                      style={{ width: '100%', marginBottom: 5 }}
                    />
                    <ErrorMessage name="message" component="div" className="text-danger" />
                  </Col>
                  <Col sm="6">
                    <p>{statusMessage ? 'E-Mail erfolgreich gesendet.' : ''}</p>
                    <button type="submit" disabled={isSubmitting || isLoading}>
                      {isLoading ? t('emailModal.sending') : t('emailModal.sendEmail')}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailModal;
