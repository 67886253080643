import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './Account.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Balance } from '../../components/Balance';
import { useTranslation } from 'react-i18next';

const Account: FC = () => {
  const { t } = useTranslation();

  const lastName = localStorage.getItem('blue_lastName') || '';
  const customerNr = localStorage.getItem('blue_customerNumber') || '';
  const smartcardNr = localStorage.getItem('blue_smartcardNumber') || '';
  const firstName = localStorage.getItem('blue_firstName') || '';

  return (
    <Container>
      <div className={styles.pageHeader}>
        <Balance />
      </div>
      <h2>{t('accountPage.title')}</h2>
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            <Card className={styles.card}>
              <Card.Header className={styles.cardHeader}>{t('accountPage.personalInfo')}</Card.Header>
              <Card.Body className={styles.cardBody}>
                <Form>
                  <Form.Group as={Row}>
                    <Col sm="6">
                      <Row>
                        <Form.Label column sm="5">
                          {t('accountPage.customerNumber')}
                        </Form.Label>
                        <Col sm="7" className={styles.paymentInputWrapper}>
                          <input value={customerNr} type="text" readOnly className={styles.paymentInput} />
                        </Col>
                        <Form.Label column sm="5">
                          {t('accountPage.firstName')}
                        </Form.Label>
                        <Col sm="7" className={styles.paymentInputWrapper}>
                          <input value={firstName} type="text" readOnly className={styles.paymentInput} />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6">
                      <Row>
                        <Form.Label column sm="5">
                          {t('accountPage.smartcardNumber')}
                        </Form.Label>
                        <Col sm="7" className={styles.paymentInputWrapper}>
                          <input value={smartcardNr} type="text" readOnly className={styles.paymentInput} />
                        </Col>
                        <Form.Label column sm="5">
                          {t('accountPage.lastName')}
                        </Form.Label>
                        <Col sm="7" className={styles.paymentInputWrapper}>
                          <input value={lastName} type="text" readOnly className={styles.paymentInput} />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
