import React, { FC } from 'react';
import styles from './PlusAccountMobile.module.scss';
import { useTranslation } from 'react-i18next';
import { useTicketsQuery } from './query';
import editIcon from '../../assets/img/editIcon.svg';
import { useNavigate } from 'react-router-dom';

interface PlusAccountMobileI {
  handleOpen: () => void;
}

const PlusAccountMobile: FC<PlusAccountMobileI> = ({ handleOpen }) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userTicket, isTicketsQueryLoading } = useTicketsQuery();
  function firstCharToUpper(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>Ticket</p>
        <p>Nächste Abbuchung</p>
        <p>Status</p>

        <p>{userTicket?.ticket}</p>
        {userTicket?.recurring_at ? userTicket.recurring_at : '--'}
        <div className={styles.status}>{userTicket?.status ? firstCharToUpper(userTicket.status) : '--'}</div>

        <div className={styles.buttons}>
          <button onClick={handleOpen} className={styles.cancelSubscriptionButton}>
            Abonnement kündigen
          </button>
          <button
            onClick={() => navigate(`/${language}/edit-payment-method`)}
            className={styles.editPaymentMethodButton}
          >
            <img src={editIcon} alt={'editICon'} />
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlusAccountMobile;
