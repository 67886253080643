import React, { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

const PrivateRoute: FC = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  useEffect(() => {}, [isAuthenticated]);

  if (loading) return null;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
