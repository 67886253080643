import { createContext } from 'react';
import userInitialState from './utils/userInitialState';
const authContext = createContext({
  token: localStorage.getItem('blue_token'),
  showModal: false,
  lastLoginData: { username: '', pin: '' },
  closeSessionModal: ()=>{},
  isAuthenticated: false,
  loading: false,
  user: userInitialState,
  error: '',
  euPortabilityStatusPopup: '',
  euPortabilityStatusPopupText: '',
  firstLogin: false,
  userLocation: '',
  login: (data: { username: string; pin: string }) => {},
  logout: () => {},
  clearErrors: () => {},
  loadUser: () => {},
  updateUser: (data: { balance: number }) => {},
  setUserLocation: (data: { userLocation: string }) => {},
});

export default authContext;
